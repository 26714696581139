export default {
  siteName: 'Lucid Source',
  enableAnimatedRoute: true,
  API_LINK: 'https://eskan.iq/api',
  // API_LINK: 'http://127.0.0.1:3000/api',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
